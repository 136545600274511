import React, { useState, useEffect, useContext } from "react";
import Background from "./assets/background.svg";
import BackgroundImage from "./assets/backgroundImage.svg";
import SmallBackgroundImage from "./assets/SmallBackgroundImages.svg";
import FingerPrint from "./assets/fingerPrint.svg";
import Flag from "./assets/Flag.svg";
import MolsLogo from "./assets/MolsLogo.svg";
import BiquLogo from "./assets/BiquLogo.svg";
import { ThemeContext } from "./context/themeContext";
import { useMutation } from "@apollo/client/react";
import { GET_LABORS_COUNT } from "./apollo-client/queries";
import AnimatedDigit from "./components/AnimateNumber";

function App() {
  const [getCount, { data, loading: laborsListCountLoading, error }] =
    useMutation(GET_LABORS_COUNT);
  const [previousCount, setPreviousCount] = useState(0);
  const [currentCount, setCurrentCount] = useState(0);
  const [textSize, setTextSize] = useState();
  const { theme } = useContext(ThemeContext);
  const [animatingDigits, setAnimatingDigits] = useState([]);

  const calculateCurrentTime = () => {
    var today = new Date();
    var time = today.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return time;
  };

  useEffect(() => {
    getCount();

    const intervalId = setInterval(() => {
      getCount();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getCount]);

  useEffect(() => {
    if (data && data.getLaborCount && data.getLaborCount.data) {
      const newCount = data.getLaborCount.data.all;
      setCurrentCount(newCount);

      if (newCount !== previousCount) {
        let prevNumberStr = previousCount.toLocaleString();
        let newNumberStr = newCount.toLocaleString();

        let prevChars = prevNumberStr.split("");
        let newChars = newNumberStr.split("");

        let updatedNumber = "";
        let animatingDigitsArray = [];

        for (let i = 0; i < newChars.length; i++) {
          if (newChars[i] !== prevChars[i]) {
            updatedNumber += newChars[i];
            animatingDigitsArray.push(i);
          } else {
            updatedNumber += prevChars[i];
          }
        }

        let finalNumber = Number(updatedNumber.replace(/,/g, ""));
        if (isNaN(finalNumber) || finalNumber < 0) {
          // Handle the case where finalNumber is NaN or negative
          console.error("Invalid data received: ", data.getLaborCount.data.all);
          setPreviousCount(0);
          setCurrentCount(0);
        } else {
          setPreviousCount(finalNumber);
          setAnimatingDigits(animatingDigitsArray);
        }
      }
    }
  }, [data, previousCount]);

  const renderDigit = (digit, index) => {
    const [animating, currentValue] = AnimatedDigit(
      digit,
      animatingDigits,
      index
    );

    return (
      <span
        className={`${
          animating ? "animate-digit" : ""
        } font-michroma text-[#3170b5]`}
      >
        {currentValue}
      </span>
    );
  };

  return (
    <>
      <div className='h-screen w-screen background-gradient'>
        <div className='relative w-full h-full flex flex-col items-center justify-center pb-[10vh]'>
          <div className='layout py-4 flex justify-between items-center'>
            <div className='flex flex-col space-y-2 w-full items-end'>
              <div className='flex items-center space-x-4 p-2 capitalize dark:bg-white bg-darker dark:text-darker text-white  shadow-custom rounded-lg px-10 w-max'>
                <p className='font-medium font-poppins text-[#001F3F]'>
                  {new Date().toDateString()}
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center justify-center gap-10 w-4/5 h-[75vh] z-40 text-white'>
            <p className='font-mitr flex items-center tracking-widest font-extralight whitespace-nowrap text-[10px] sm:text-[15px] md:text-[20px] lg:text-[25px] xl:text-[30px] 2xl:text-[35.64px]'>
              E-LMIS Labour Registrati
              <img
                src={FingerPrint}
                alt='FingerPrint'
                className='h-7 mt-1 mr-0.5'
              />
              n
            </p>
            <p className='text-[30px] sm:text-[40px] md:text-[80px] lg:text-[120px] xl:text-[170px] 2xl:text-[210px] font-michroma mx-auto h-max leading-none -mt-4'>
              {currentCount.toLocaleString()}
            </p>

            <p className='text-xs font-manrope max-w-2xl font-light text-center mt-10'>
              Total Workforce Enrolled in E-LMIS: This number signifies the
              overall size of the labor force that has successfully registered
              and is part of the Ethiopian Labor Market Information System.
            </p>
          </div>

          <div className='h-full w-full mix-blend-color-burn bg-[#FFCA7B]/40 absolute top-0 left-0 z-10'></div>
          <img
            src={Background}
            className='w-full h-3/5 absolute bottom-0 left-0 z-20'
          />
          <img
            src={BackgroundImage}
            className='w-full h-full absolute top-0 left-0 object-cover opacity-20 z-20'
          />
          <img
            src={SmallBackgroundImage}
            className='w-full h-full absolute top-0 left-0 object-cover opacity-70 z-20'
          />

          <div className='md:w-30 w-52 h-30 md:h-52 absolute bottom-5 md:-translate-y-1/2 md:top-1/2 md:right-20 object-cover z-40 flex md:flex-col justify-between items-center'>
            <img src={BiquLogo} className='h-6 w-max' />
            <img src={Flag} className='h-6 w-max' />
            <img src={MolsLogo} className='h-6 w-max' />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
